// NOTE: This is a temporary wrapper file to allow use of the DownloadPdfQuoteButton React component in ERB files, by adding the SignInProvider
import React from 'react'
import SignInProvider from '../../components/common/providers/SignInProvider'
import DownloadPdfQuoteButton, { DownloadPdfQuoteButtonProps } from '../common/DownloadPdfQuoteButton'

const ErbDownloadPdfQuoteButton: React.FC<DownloadPdfQuoteButtonProps> = ({
  userId,
  design,
  label = 'PDF Quote',
  showArrowIcon = true,
  includeMachineLogicSoftware,
  includeRobotPartnerParts,
  analyticId,
}) => {
  return (
    <SignInProvider>
      <DownloadPdfQuoteButton
        design={design}
        userId={userId}
        showArrowIcon={showArrowIcon}
        label={label}
        includeMachineLogicSoftware={includeMachineLogicSoftware}
        includeRobotPartnerParts={includeRobotPartnerParts}
        analyticId={analyticId}
      />
    </SignInProvider>
  )
}

export default ErbDownloadPdfQuoteButton
