import React from 'react'

interface Props {
  commentCount: number
  isCommunityDesign?: boolean
}

const NoUserCommentBox: React.FC<Props> = props => {
  return props.commentCount === 0 ? (
    <div className='no-user-comment-box-sign-in'>
      <img
        className='no-user-comment-box-image'
        src='https://assets.vention.io/page-assets/designs/view/comments.svg'
        alt='No user comments'
      />
      <div className='no-user-comment-box-subheader'>
        Be the first to{' '}
        {props.isCommunityDesign ? 'provide feedback on this design' : 'share your question'}{' '}
      </div>
      <a href={'/users/sign_in/'} className='no-user-comment-box-button'>
        Sign in to comment
      </a>
    </div>
  ) : (
    <div className='no-user-comment-box-input'>
      <div className='no-padding flex-centering'>
        <a href={'/users/sign_in/'} className='comment-profile-anchor'>
          <img
            src='https://assets.vention.io/png/avatars/default_avatar.png'
            className={'img-circle img-responsive center-self'}
          />
        </a>
      </div>
      <a href={'/users/sign_in/'} className='no-user-comment-box-button with-comments'>
        Sign in to comment
      </a>
    </div>
  )
}

export default NoUserCommentBox
