import React from 'react'
import ModalOpen from '../../components/common/modals/ModalOpen'
import SignInModal from '../../components/common/modals/SignInModal'
import { SignInConsumer } from '../../components/common/providers/SignInProvider'
import requestPdfQuoteModal from '../../helpers/RequestPdfQuoteModal'
import { Design } from '../../api/Designs/Types'
import DesignActionRequiredModal from '../../components/designs/DesignActionRequiredModal'

export interface DownloadPdfQuoteButtonProps {
  readonly userId?: string;
  readonly label: string;
  readonly showArrowIcon: boolean;
  readonly includeMachineLogicSoftware?: boolean;
  readonly includeRobotPartnerParts?: boolean;
  readonly design: Pick<Design, 'id' | 'is_vention_verified' | 'lead_designer_id' | 'has_obsolete_parts'>;
  readonly analyticId?: string;
}

const DownloadPdfQuoteButton: React.FC<DownloadPdfQuoteButtonProps> = ({
  userId,
  design,
  label = 'PDF Quote',
  showArrowIcon = true,
  includeMachineLogicSoftware,
  includeRobotPartnerParts,
  analyticId,
}) => {
  
  return (
    <>
      <SignInConsumer>
        {signInContext => (
          <ModalOpen
            buttonComponent={
              <button className='document-downloads-button' data-analytic-id={analyticId}>
                <i className='icon-proposal download_icon' />
                <span className='document-downloads-button-label'>{label}</span>
                {showArrowIcon && <i className='far fa-arrow-to-bottom' />}
              </button>
            }
            modalComponent={
              design.has_obsolete_parts ? (
                <DesignActionRequiredModal currentUserId={userId} design={design} />
              ) : (
                <SignInModal
                  currentUser={signInContext.currentUser}
                  successCallback={() =>
                    requestPdfQuoteModal(
                      design.id,
                      includeMachineLogicSoftware,
                      includeRobotPartnerParts
                    )
                  }
                />
              )
            }
          />
        )}
      </SignInConsumer>
    </>
  )
}

export default DownloadPdfQuoteButton
