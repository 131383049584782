import React from 'react'
import CommentBox from './CommentBox'
import moment from 'moment'
import ModalOpen from '../common/modals/ModalOpen'
import SignInModal from '../common/modals/SignInModal'
import SignInProvider, { SignInConsumer } from '../common/providers/SignInProvider'
import classNames from 'classnames'
import { User } from '../../api/Users/Types'

export type CommentProps = {
  readonly id: number;
  readonly likes_count: string;
  readonly sub_comments?: any[];
  readonly user_id: number;
  readonly current_user: User;
  readonly content: string;
  readonly level_down: any;
  readonly submitComment: any[];
  readonly showDeleteConfirmation: (id: number) => void;
  readonly display_name: string;
  readonly sub_comment: string;
  readonly user_avatar: string;
  readonly created_at: Date;
  readonly toggleCommentBox: () => void;
}

export type CommentState = {
  readonly reply: any;
  readonly likedByCurrentUser: any;
  readonly likes_count: string;
  readonly sub_comments: any[];
  readonly showEditMenu?: boolean;
  readonly content: string;
  readonly editing?: boolean;
}

class Comment extends React.Component<CommentProps, CommentState> {

  editing_textarea: React.RefObject<any>

  constructor(props) {
    super(props)
    this.state = {
      likes_count: this.props.likes_count,
      likedByCurrentUser: false,
      reply: false,
      sub_comments: this.props.sub_comments ?? [],
      showEditMenu: this.props.user_id === this.props.current_user.id || this.props.current_user.admin_on,
      content: this.props.content,
    }
    this.toggleLikes = this.toggleLikes.bind(this)
    this.toggleCommentBox = this.toggleCommentBox.bind(this)
    this.closeCommentBox = this.closeCommentBox.bind(this)
    this.modifyContent = this.modifyContent.bind(this)
    this.offModifyingContent = this.offModifyingContent.bind(this)
    this.saveModifyingContent = this.saveModifyingContent.bind(this)
    this.editing_textarea = React.createRef()
    this.displayTimeStamp = this.displayTimeStamp.bind(this)
  }
  componentWillMount() {
    $('.img-circle')
      .on('load', function () {
        console.log('image loaded correctly')
      })
      .on('error', function () {
        this.src = 'https://assets.vention.io/png/avatars/default_avatar.png'
      })
    $('.comment_item [data-toggle="popover"]').popover({
      animation: false,
      placement: 'auto top',
      container: 'body',
    })
    if (this.props.current_user) {
      let that = this
      $.ajax({
        url: `/comments/${this.props.id}/like`,
        headers: { parent: 'comment', Accept: 'application/json' },
        type: 'GET',
        success: data => {
          that.setState({
            likedByCurrentUser: data.res,
            likes_count: data.likes_count,
          })
        },
      })
    }
  }
  closeCommentBox() {
    this.setState({ reply: false })
  }
  toggleCommentBox() {
    this.setState({ reply: !this.state.reply })
  }
  modifyContent() {
    this.setState({ editing: true })
  }
  offModifyingContent() {
    this.setState({ editing: false })
  }
  saveModifyingContent() {
    const newContent = this.editing_textarea.current.value.replace(/^\s+|\s+$/g, '')
    $.ajax({
      url: `/comments/${this.props.id}`,
      headers: { parent: 'comment' },
      data: {
        payload: JSON.stringify({ id: this.props.id, content: newContent }),
      },
      type: 'PUT',
      success: () => {
        this.setState({ content: newContent, editing: false })
      },
    })
  }
  formatContent(textString) {
    const matchUrl = new RegExp(/(https?:\/\/[^\s]+)/, 'g')
    return textString.replace(matchUrl, url => {
      return `<a href=${url} target="_blank" rel="noopener noreferrer nofollow">${url}</a>`
    })
  }

  toggleLikes() {
    $.ajax({
      url: `/comments/${this.props.id}/like`,
      headers: { parent: 'comment' },
      data: { payload: 'vention' },
      type: 'POST',
      success: data => {
        this.setState({
          likedByCurrentUser: data.res,
          likes_count: data.likes_count,
        })
        toastr.success('Liked!')
      },
    })
  }
  displayTimeStamp(created_at_time) {
    const createdAt = moment(created_at_time)

    return createdAt.format('l')
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.level_down) {
      if (nextProps.sub_comments.length !== this.state.sub_comments.length) {
        this.setState({ sub_comments: nextProps.sub_comments })
      }
    }
  }
  render() {
    let sub_comments =
      this.state.sub_comments && this.state.sub_comments.length
        ? this.state.sub_comments
          .sort((a, b) => a.id - b.id)
          .map(comment => (
            <Comment
              key={comment.id}
              sub_comment={true}
              current_user={this.props.current_user}
              {...comment}
              toggleCommentBox={this.toggleCommentBox}
              submitComment={this.props.submitComment}
              level_down={true}
              showDeleteConfirmation={this.props.showDeleteConfirmation}
            />
          ))
        : null,
      formattedContent = this.formatContent(this.state.content)
    return (
      <SignInProvider currentUser={this.props.current_user}>
        <div
          className={classNames('comment_item', { 'sub-comment-wrapper': this.props.sub_comment })}
        >
          <div style={{ maxWidth: this.props.level_down ? '3rem' : 'initial' }}>
            <a href={`/users/${this.props.user_id}`} className='center-self comment-profile-anchor'>
              <img
                src={
                  this.props.user_avatar
                    ? this.props.user_avatar
                    : 'https://assets.vention.io/png/avatars/default_avatar.png'
                }
                alt='User avatar'
                className='img-circle img-responsive center-self'
              />
            </a>
          </div>
          <div>
            <div className='comment-options-wrapper'>
              <div className='comment-options-commenter-info-wrapper'>
                <div className='comment-options-commenter-info'>
                  <a href={`/users/${this.props.user_id}`}>{this.props.display_name}</a>
                  <span className='flex-centering comment_item_span'>
                    <span>{this.displayTimeStamp(this.props.created_at)}</span>
                  </span>
                </div>
                {this.state.showEditMenu ? (
                  <div className='dropdown'>
                    <span className='dropdown-toggle  flex-centering' data-toggle='dropdown'>
                      <i className='fal fa-ellipsis-h'></i>
                    </span>
                    <ul
                      className='dropdown-menu dropdown-menu-right'
                      aria-labelledby='dropdownMenuButton'
                    >
                      <li className='dropdown-item' onClick={this.modifyContent}>
                        <a>Edit</a>
                      </li>
                      <li
                        className='dropdown-item'
                        onClick={() => this.props.showDeleteConfirmation(this.props.id)}
                      >
                        <a>Delete</a>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </div>
              {
                this.state.editing ? (
                  <div>
                    <textarea
                      maxLength={1000}
                      style={{ resize: 'none', width: '95%' }}
                      className='form-control'
                      placeholder='Add a comment...'
                      defaultValue={this.state.content}
                      ref={this.editing_textarea}
                    />
                    <button
                      className='button button-success'
                      onClick={this.saveModifyingContent}
                      style={{ margin: '1rem 1rem 1rem 0' }}
                    >
                      Update
                    </button>
                    <button className='button button-white' onClick={this.offModifyingContent}>
                      Cancel
                    </button>
                  </div>
                ) : (
                  <div
                    className='no-padding comment-container'
                    dangerouslySetInnerHTML={{ __html: formattedContent }}
                  ></div>
                )}

              <SignInConsumer>
                {
                  signInContext => (
                    <div className='comment_item_bottom'>
                      <span className='flex-centering'>
                        {
                          this.state.likedByCurrentUser ? (
                            <ModalOpen
                              buttonComponent={
                                <i
                                  className='fas fa-thumbs-up fa-1x pointer-cursor'
                                  aria-hidden='true'
                                ></i>
                              }
                              modalComponent={
                                <SignInModal
                                  currentUser={signInContext.currentUser}
                                  successCallback={this.toggleLikes}
                                />
                              }
                            />
                          ) : (
                            <ModalOpen
                              buttonComponent={
                                <i
                                  className='fal fa-thumbs-up fa-1x pointer-cursor'
                                  aria-hidden='true'
                                ></i>
                              }
                              modalComponent={
                                <SignInModal
                                  currentUser={signInContext.currentUser}
                                  successCallback={this.toggleLikes}
                                />
                              }
                            />
                          )}
                      </span>
                      <div className='likes_text'>
                        {parseInt(this.state.likes_count) === 0 ? null : (
                          <>{this.state.likes_count}</>
                        )}
                      </div>
                      <span className='flex-centering'>
                        <span
                          className='comment-reply-link link-underline-light'
                          onClick={
                            this.props.level_down
                              ? this.props.toggleCommentBox
                              : this.toggleCommentBox
                          }
                        >
                          REPLY
                        </span>
                      </span>
                    </div>
                  )}
              </SignInConsumer>
            </div>
            {this.state.reply ? (
              <div className='reply-comment-box'>
                <CommentBox
                  submitComment={this.props.submitComment}
                  currentUser={this.props.current_user}
                  parent_id={this.props.id}
                  closeCommentBox={this.closeCommentBox}
                />
              </div>
            ) : null}
            {sub_comments}
          </div>
        </div>
      </SignInProvider>
    )
  }
}

export default Comment
