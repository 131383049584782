const CommentService = {
  deleteComment(pCommentId: number, callback?: () => void): void {
    $.ajax({
      url: '/comments/' + pCommentId,
      headers: { parent: 'comment' },
      type: 'DELETE',
      success: function () {
        if (callback) {
          callback()
        }
      },
      error: function () {
        toastr.error('There was a problem removing your comment.', '', {
          timeOut: 10000,
        })
      },
    })
  },

  submitComment(pComment, pCommentParentId, pUrl, pParentModel, cb) {
    $.ajax({
      url: pUrl,
      headers: { parent: pParentModel },
      type: 'POST',
      data: {
        payload: JSON.stringify({
          content: pComment,
          parent_id: pCommentParentId,
        }),
      },
      success: function (res) {
        if (res) {
          cb(res)
        } else {
          toastr.info('Check your email to activate your account before continuing.', '', {
            timeOut: 20000,
          })
        }
      },
      error: function () {
        toastr.error('There was a problem submitting your comment.', '', {
          timeOut: 10000,
        })
      },
    })
  },
}

export default CommentService
