import React, { useState } from 'react'
import SignInModal from '../../components/common/modals/SignInModal'
import ModalOpen from '../../components/common/modals/ModalOpen'
import { SignInConsumer, SignInContextType } from '../../components/common/providers/SignInProvider'
import { User } from '../../api/Users/Types'
import { Part } from '../../api/Parts/Types'
import DesignService from '../../api/Designs/Service'
import Button from '../../components/common/buttons/Button'

interface Props {
  buttonText: string
  design_id: number
  new_parts: (Part & { quantity: number })[]
  displayArrow?: boolean
}

const DownloadBomButton: React.FC<Props> = props => {
  const [isLoading, setIsLoading] = useState(false)

  const downloadFile = (currentUser: User | undefined, path: string) => {
    const pathCallback = typeof path === 'function' ? path : () => window.open(path)

    const currentUserActivated =
      currentUser && typeof currentUser.activated === 'boolean'
        ? currentUser.activated
        : currentUser && currentUser.activated === ''

    if (currentUser) {
      if (!currentUserActivated) {
        toastr.error('Please activate your account in order to download documents')
      } else {
        pathCallback()
      }
    }
  }

  const downloadBom = (signInContext: SignInContextType) => {
    return (currentUser?: User) => {
      setIsLoading(true)
      downloadFile(
        signInContext.currentUser || currentUser,
        DesignService.downloadBom.bind(null, props.design_id, () => setIsLoading(false)) as any
      )
    }
  }

  const showButton = (signInContext: SignInContextType): boolean =>
    !!signInContext.currentUser?.admin_on

  return (
    <>
      <SignInConsumer>
        {signInContext =>
          showButton(signInContext) && (
            <ModalOpen
              buttonComponent={
                <Button
                  isLoading={isLoading}
                  className='float-left flex-centering button-link btn-ci download-bom-button'
                  data-turbolinks='false'
                >
                  <i className='icon-BOM download_icon' />
                  {props.buttonText}
                  {props.displayArrow && <i className='far fa-arrow-to-bottom' />}
                </Button>
              }
              modalComponent={
                <SignInModal
                  currentUser={signInContext.currentUser}
                  successCallback={downloadBom(signInContext)}
                />
              }
            />
          )
        }
      </SignInConsumer>
    </>
  )
}

export default DownloadBomButton
