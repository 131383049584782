import React from 'react'
import NoUserCommentBox from './NoUserCommentBox.tsx'

class CommentBox extends React.Component {
  constructor(props) {
    super(props)
    this.submitComment = this.submitComment.bind(this)
    this.comment = React.createRef()
  }
  submitComment(event) {
    event.preventDefault()
    if (this.comment.current.value.trim() === '') {
      toastr.error('Cannot submit an empty comment.')
      return
    }
    this.props.submitComment(this.comment.current.value, this.props.parent_id)
    setTimeout(() => {
      this.comment.current.value = ''
      this.comment.current.setSelectionRange(0, 0)
      toastr.success('Thank you for your feedback')
    })
  }

  render() {
    let avatar =
      this.props.currentUser && this.props.currentUser.avatar_url
        ? this.props.currentUser.avatar_url
        : 'https://assets.vention.io/png/avatars/default_avatar.png'

    return $.isEmptyObject(this.props.currentUser) ? (
      <NoUserCommentBox
        commentCount={this.props.commentCount}
        isCommunityDesign={this.props.isCommunityDesign}
      />
    ) : (
      <form className='comment_box'>
        <div>
          <a
            className='center-self comment-profile-anchor'
            href={`/users/${this.props.currentUser.id}`}
          >
            <img
              src={avatar}
              alt='User avatar'
              className='img-circle img-responsive comment-image center-self'
              loading='lazy'
            />
          </a>
        </div>
        <div>
          <textarea
            ref={this.comment}
            type='text'
            style={{ resize: 'none' }}
            maxLength='1000'
            className='form-control'
            placeholder='Add a comment...'
          />
          <button className='button-success' onClick={this.submitComment}>
            Comment
          </button>
        </div>
      </form>
    )
  }
}
export default CommentBox
